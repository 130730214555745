<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <b>v.BETA-1.0</b> © 2021
      <b-link
        class="ml-25"
        href="https://evorgy.com"
        target="_blank"
      >Evorgy S.L.</b-link>
      <span class="d-none d-sm-inline-block">, todos los derechos reservados.</span>
    </span>

    <span class="float-md-right d-none d-md-block">Made in Zaragoza
      <feather-icon
        icon="HeartIcon"
        size="18"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
