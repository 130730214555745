export default [
  {
    title: 'Empresas Instaladoras',
    icon: 'UserIcon',
    route: 'apps-users-list',
  },
  {
    title: 'Usuarios',
    icon: 'UserIcon',
    route: 'apps-customers-users-list',
  },
  {
    title: 'Próximamente',
    icon: 'MenuIcon',
    children: [
      {
        title: 'Noticias',
        route: null,
      },
      {
        title: 'Servicios',
        route: null,
      },
      {
        title: 'Integraciones',
        route: null,
      },
      {
        title: 'EVOstore',
        route: null,
      },
    ],
  },
]
